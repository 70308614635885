<template>
  <svg class="report" viewBox="0 0 40 28" width="40" height="28">
    <path
      d="M32.7,28H7.3c-0.8,0-1.4-0.6-1.4-1.4v-3.1c0-0.8,0.6-1.4,1.4-1.4h25.5c0.8,0,1.4,0.6,1.4,1.4v3.1
		C34.2,27.4,33.5,28,32.7,28z"
    />
    <path
      class="light"
      fill="#FF0000"
      d="M12.5,20H8.9l1.5-11.4c0-2.4,1.7-4.4,3.7-4.4h11.8c2.1,0,3.7,1.9,3.7,4.4L31.1,20H14.8l1.3-10.3
		c0.1-0.5-0.3-0.9-0.8-1l-0.5-0.1c-0.5-0.1-1,0.3-1,0.8L12.5,20z M7.2,2.1L4.3,0.3C3.6-0.2,2.6,0,2.1,0.7l0,0C1.5,1.5,1.8,2.5,2.5,3
		l2.8,1.8c0.8,0.5,1.8,0.3,2.3-0.5l0,0C8.1,3.6,7.9,2.6,7.2,2.1z M6.7,12.1L6.7,12.1c0,0.9-0.7,1.6-1.6,1.6l-3.4,0
		C0.7,13.7,0,13,0,12.1l0,0c0-0.9,0.7-1.6,1.6-1.6l3.4,0C6,10.4,6.7,11.2,6.7,12.1z M35.7,0.3l-2.8,1.8c-0.8,0.5-1,1.5-0.5,2.3v0
		c0.5,0.7,1.5,1,2.3,0.5L37.5,3c0.8-0.5,1-1.5,0.5-2.3l0,0C37.4,0,36.4-0.2,35.7,0.3z M33.3,12.1L33.3,12.1c0-0.9,0.7-1.6,1.6-1.6
		l3.4,0c0.9,0,1.6,0.7,1.6,1.6v0c0,0.9-0.7,1.6-1.6,1.6l-3.4,0C34,13.7,33.3,13,33.3,12.1z"
    />
  </svg>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.report {
  width: 40px;
  height: 28px;
  cursor: pointer;
  &:hover {
    & .light {
      animation: flash 500ms both infinite;
      @keyframes flash {
        from {
          fill: red;
        }
        to {
          fill: blue;
        }
      }
    }
  }
}
</style>
