<template>
  <div class="logos container-fluid mb-4">
    <svg
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 275 450"
      enable-background="new 0 0 275 450"
      xml:space="preserve"
    >
      <g id="logo">
        <g id="logo_copy">
          <path
            id="acts"
            fill="#45C3FF"
            d="M65.8,302.2l-23.6,48.2h9.1l4.7-10h19.7l4.7,10h9L65.8,302.2z M59.1,333.8l6.7-14.4l6.8,14.4
		H59.1z M129.5,338v9.9c-3.4,2.1-7.5,3.2-12,3.2c-13.2,0-23.9-10.7-23.9-24c0-13.2,10.7-24,23.9-24c4.4,0,8.6,1.1,12,3.2v9.9
		c-2.7-3.1-6.9-5-11.8-5c-8.8,0-15.5,6.9-15.5,15.8s6.7,15.8,15.5,15.8C122.6,343,126.8,341,129.5,338z M198.9,343.8
		c4.7,0,8-2.1,8-5.9c0-3-2-4.3-4.9-5.8l-7.7-3.7c-5-2.4-9.3-5.4-9.3-11.9c0-8.1,6.6-13.3,15.2-13.3c5.3,0,10,2.2,12.5,4.6v8.9
		c-3.6-3.6-8.2-6.2-12.8-6.2c-3.9,0-6.8,1.9-6.8,5.3c0,2.5,1.8,3.9,4.4,5.1l7.2,3.4c6.7,3.1,10.3,6.3,10.3,12.8
		c0,8.6-7.2,14-16.3,14c-5.9,0-11.1-2.2-14.1-4.6v-8.8C188.6,341,193.6,343.8,198.9,343.8z M161.9,287.1v19h17.8v9h-17.8v35.3h-8.6
		v-35.3h-17.8v-9h17.8v-19H161.9z"
          />
          <path
            id="of"
            fill="#FFAA00"
            d="M66.6,362.6c-8.1,0-14.5,6.6-14.5,14.7c0,8.1,6.5,14.7,14.5,14.7c8.1,0,14.5-6.6,14.5-14.7
			C81.1,369.2,74.6,362.6,66.6,362.6z M66.6,387.2c-5.1,0-9.3-4.2-9.3-9.8c0-5.6,4.1-9.8,9.3-9.8c5.1,0,9.3,4.2,9.3,9.8
			C75.8,383,71.7,387.2,66.6,387.2z M90.6,363.1h17.1v4.4H95.7v7.6h11v4.4h-11v12.1h-5.1V363.1z"
          />
          <path
            id="the"
            fill="#707070"
            d="M134.3,367.5v24.1h-5.3v-24.1h-9.1v-4.4h23.5v4.4H134.3z M170.7,379.3h-13.3v12.3h-5.3v-28.5h5.3
			v11.8h13.3v-11.8h5.3v28.5h-5.3V379.3z M187,363.1h17.6v4.4h-12.4v7.4h11.4v4.4h-11.4v8h12.5v4.4H187V363.1z"
          />
          <path
            id="church"
            fill="#574226"
            d="M35.1,437.5v9.7c-3.4,2-7.4,3.2-11.7,3.2C10.4,450.4,0,439.9,0,427s10.4-23.4,23.3-23.4
			c4.3,0,8.4,1.1,11.7,3.2v9.7c-2.7-3-6.8-4.8-11.5-4.8c-8.6,0-15.1,6.7-15.1,15.4s6.6,15.4,15.1,15.4
			C28.3,442.4,32.4,440.5,35.1,437.5z M77,430H55.9v19.6h-8.4v-45.3h8.4V423H77v-18.7h8.4v45.3H77V430z M117,443
			c5.8,0,9.7-4,9.7-11.3v-27.4h8.4v27.4c0,11.8-7.9,18.6-18.1,18.6c-10.1,0-18.1-6.8-18.1-18.6v-27.4h8.4v27.4
			C107.3,439,111.3,443,117,443z M169.7,430.7c5.5-2,8.9-6.3,8.9-12.4c0-8.9-7.1-14-17.1-14h-12.8v45.3h8.4v-17.5h4l13,17.5h9.7
			L169.7,430.7z M161.4,425.4H157v-14.2h4.4c5.2,0,8.6,2.5,8.6,7.1C170.1,422.9,166.6,425.4,161.4,425.4z M224.7,437.5v9.7
			c-3.4,2-7.4,3.2-11.7,3.2c-12.9,0-23.3-10.5-23.3-23.4s10.4-23.4,23.3-23.4c4.3,0,8.4,1.1,11.7,3.2v9.7c-2.7-3-6.8-4.8-11.5-4.8
			c-8.6,0-15.1,6.7-15.1,15.4s6.6,15.4,15.1,15.4C218,442.4,222.1,440.5,224.7,437.5z M266.6,430h-21.1v19.6h-8.4v-45.3h8.4V423
			h21.1v-18.7h8.4v45.3h-8.4V430z"
          />
        </g>
        <g id="flame_two_clip_group">
          <defs>
            <path
              id="flame_two_mask"
              d="M216,42.9c-24.2,0-47.8-22.7-76-22.7c-15.4,0-30.2,6.8-43.2,27.8c8.2-6.6,14.1-9.2,19.1-9.2
				c16.6,0,21.9,28.6,61.8,28.6c0.9,0,1.8,0,2.7,0c-7-0.4-14.9-7.5-17.3-11.4c29.7,11.5,61.9-2.3,69-17.6
				C227.2,41.6,221.8,42.9,216,42.9z M148.5,42.9c2.1,4.4,5.6,11.7,5.6,11.7c-6.6-3.3-11.3-7.7-15.8-11.8
				c-4.7-4.3-9.4-8.7-15.5-10.7c0,0,4-2.1,17.3-4.2c12.4-1.9,43.5,19.4,65.9,21.7C199.8,51.6,181,58,148.5,42.9z"
            />
          </defs>
          <clipPath id="flame_two_mask_1_">
            <use xlink:href="#flame_two_mask" overflow="visible" />
          </clipPath>
          <g id="flame_two_bg_group" clip-path="url(#flame_two_mask_1_)">
            <path
              id="flame_two_bg"
              fill="#45C3FF"
              d="M232.2,67.4H96.9V20.2h135.3V67.4z"
            />
          </g>
        </g>
        <path
          id="sun"
          fill="none"
          stroke="#FFAA00"
          stroke-width="16"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="M59.8,270.9
		c-10.1-15-16-33.2-16-52.7c0-52,42-94.2,93.7-94.2s93.7,42.2,93.7,94.2c0,19.3-5.8,37.3-15.7,52.3"
        />
        <g id="cross">
          <line
            id="hor"
            fill="none"
            stroke="#574226"
            stroke-width="15.5"
            stroke-miterlimit="10"
            x1="137.5"
            y1="179.9"
            x2="137.5"
            y2="13.5"
          />
          <line
            id="ver"
            fill="none"
            stroke="#574226"
            stroke-width="15.5"
            stroke-miterlimit="10"
            x1="68.5"
            y1="84"
            x2="206.5"
            y2="84"
          />
        </g>
        <g id="mtn_bg_clip_group">
          <defs>
            <path
              id="mtn_bg_mask"
              d="M137.5,165.4L0,350.7l108.4-116.5l36.9,25.5l0.1-27.1L275,350.7L137.5,165.4z M129.7,211.3l-0.3,22.1
				l-12.7-9.5l20.8-28.5l43.5,50.2L129.7,211.3z"
            />
          </defs>
          <clipPath id="mtn_bg_mask_1_">
            <use xlink:href="#mtn_bg_mask" overflow="visible" />
          </clipPath>
          <g id="mtn_bg_group" clip-path="url(#mtn_bg_mask_1_)">
            <path
              id="mtn_bg"
              fill="#707070"
              d="M275,350.7H0V165.4h275V350.7z"
            />
          </g>
        </g>
        <g id="flame_three_clip_group">
          <defs>
            <path
              id="flame_three_mask"
              d="M112,49.2c-19.4,20.1-5.6,33.8,33.3,42.6C106.5,95.2,53.4,76.4,112,49.2z"
            />
          </defs>
          <clipPath id="flame_three_mask_1_">
            <use xlink:href="#flame_three_mask" overflow="visible" />
          </clipPath>
          <g id="flame_three_bg_group" clip-path="url(#flame_three_mask_1_)">
            <path
              id="flame_three_bg"
              fill="#45C3FF"
              d="M145.3,92.2H86v-43h59.4V92.2z"
            />
          </g>
        </g>
        <g id="flame_one_clip_group">
          <defs>
            <path
              id="flame_one_mask"
              d="M155.9,0.4c26.4,0,50,22.2,70,22.2c5.1,0,9.6-1.5,13.2-5.2c-5.8,9.7-13.2,13.2-21.8,13.2
				c-21.8,0-44.5-22.7-71.8-22.7c-5.4,0-10.6,0.9-15.8,3C137.5,3.2,146.4,0.4,155.9,0.4L155.9,0.4z"
            />
          </defs>
          <clipPath id="flame_one_mask_1_">
            <use xlink:href="#flame_one_mask" overflow="visible" />
          </clipPath>
          <g id="flame_one_bg_group" clip-path="url(#flame_one_mask_1_)">
            <path
              id="flame_one_bg"
              fill="#45C3FF"
              d="M239.1,30.7H129.7V0.4h109.4V30.7z"
            />
          </g>
        </g>
        <g id="flame_four_clip_group">
          <defs>
            <path
              id="flame_four_mask"
              d="M129.6,101.3c-4.5-1.2-9.5-2.3-14.9-3.4c5.3,0,10.3,0.1,14.9,0.4V101.3z M154.6,152.6
				c13.5-4.7,35.9-18.2,21.8-36.5C195.6,122.5,207.6,143.5,154.6,152.6z M145.3,99.7v6.8c48.5,16.6,19.2,32.5-15.7,34.6
				c3,1.2,11.4,3.4,19.5,3.4c2.2,0,4.5-0.2,6.6-0.6c-5.3,9.9-42.8,13.1-56,13.8c11.2,1.1,21.5,1.6,30.8,1.6
				C224.1,159.3,220.2,108.8,145.3,99.7L145.3,99.7z"
            />
          </defs>
          <clipPath id="flame_four_mask_1_">
            <use xlink:href="#flame_four_mask" overflow="visible" />
          </clipPath>
          <g id="flame_four_bg_group" clip-path="url(#flame_four_mask_1_)">
            <path
              id="flame_four_bg"
              fill="#45C3FF"
              d="M201.2,159.3H99.7V97.9h101.5V159.3z"
            />
          </g>
        </g>
      </g>
    </svg>
    <div class="icon-scroll mt-4"></div>
    <svg class="fish mt-4" viewBox="0 0 40 70" width="40" height="70">
      <g id="fish">
        <path
          id="fl"
          fill="none"
          stroke="#45C3FF"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M19.9,68.2c0,0-14.2-10.1-14.2-25.9s8.7-22.6,28.8-40.5"
        />
        <path
          id="fr"
          fill="none"
          stroke="#45C3FF"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M5.5,1.8c20.1,17.9,28.8,24.7,28.8,40.5S20.1,68.2,20.1,68.2"
        />
        <path
          id="fl_rev"
          fill="none"
          stroke="#45C3FF"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M34.5,1.8C14.4,19.7,5.8,26.6,5.8,42.3S20,68.2,20,68.2"
        />
        <path
          id="fr_rev"
          fill="none"
          stroke="#45C3FF"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M20.1,68.2c0,0,14.2-10,14.2-25.8S25.7,19.7,5.5,1.8"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { onMounted, onUnmounted } from "@vue/runtime-core";
export default {
  props: ["loaded"],
  data() {
    return {
      delay: 1.3,
    };
  },
  setup() {
    let tl_fish = null;
    onMounted(() => {
      tl_fish = gsap.timeline({ repeat: -1 });
      tl_fish.add("start", ">");
      tl_fish.set("#fl,#fr_rev", { opacity: 0, drawSVG: "100%" }, "start");
      tl_fish.set("#fl_rev,#fr", { opacity: 1, drawSVG: "0%" }, "start");
      tl_fish.to(
        "#fl_rev",
        {
          duration: 1,
          drawSVG: "100%",
          ease: "power0.none",
        },
        ">"
      );
      tl_fish.to(
        "#fr",
        {
          duration: 1,
          drawSVG: "100%",
          ease: "power0.none",
        },
        ">-.5"
      );
      tl_fish.set("#fl_rev,#fr", { opacity: 0 }, ">");
      tl_fish.set("#fl,#fr_rev", { opacity: 1 }, ">");
      tl_fish.to(
        "#fl",
        {
          duration: 1,
          drawSVG: " 0%",
          ease: "power0.none",
        },
        ">+.5"
      );
      tl_fish.to(
        "#fr_rev",
        {
          duration: 1,
          drawSVG: "0%",
          ease: "power0.none",
        },
        ">-.5"
      );
    });
    onUnmounted(() => {
      tl_fish.kill();
      tl_fish = null;
    });
    return {};
  },
  created() {
    gsap.registerPlugin(DrawSVGPlugin);
  },
  mounted() {
    if (this.loaded) {
      this.delay = 0;
    }

    let tl = gsap.timeline({
      id: "tl",
      paused: false,
      delay: this.delay,
      defaults: { duration: 1, ease: "power4.out" },
    });
    tl.add("start", "<");
    tl.from("#mtn_bg", { y: -190 }, "start");
    tl.from("#sun", { drawSVG: "50% 50%" }, "start");
    tl.from("#hor", { drawSVG: "0%" }, "start+=.2");
    tl.from("#ver", { drawSVG: "50% 50%" }, "start+=.2");
    tl.from("#flame_four_bg", { y: 62 }, "start+=.2");
    tl.from("#flame_three_bg", { y: 44 }, "start+=.5");
    tl.from("#flame_two_bg", { x: -137 }, "start+=.6");
    tl.from("#flame_one_bg", { x: -111 }, "start+=.8");
    tl.from(
      "#acts",
      {
        duration: 1.5,
        opacity: 0,
        y: 10,
        transformOrigin: "center",
        ease: "power3.out",
      },
      "start"
    );
    tl.from(
      "#of,#the",
      {
        duration: 1.5,
        opacity: 0,
        y: 10,
        transformOrigin: "center",
        ease: "power3.out",
      },
      "start+=.3"
    );
    tl.from(
      "#church",
      {
        duration: 1.5,
        opacity: 0,
        y: 10,
        transformOrigin: "center",
        ease: "power3.out",
      },
      "start+=.6"
    );
  },
};
</script>

<style lang="scss" scoped>
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding: 0 !important;
  position: relative;
}
#logo {
  width: 250px;
  overflow: visible;
  margin-top: 10px;
}
.logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-scroll {
  position: relative;
  width: 40px;
  height: 70px;
  box-shadow: inset 0 0 0 2px #707070;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  display: none;
}
.icon-scroll::before {
  position: absolute;
  top: 8px;
  content: "";
  width: 8px;
  height: 8px;
  background: #707070;
  border-radius: 4px;
  animation: scroll 1.5s infinite;
  @keyframes scroll {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(46px);
    }
  }
}
</style>
