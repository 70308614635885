<template>
  <div class="about container-fluid py-4">
    <h1>At Your <span class="blue">Service</span></h1>
    <p class="px-4">
      "Bear ye one another's <span class="strike">burdens</span>, and so fulfill
      the law of Christ."
      <i>Galations 6:2</i>
    </p>
    <p class="px-4">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure consectetur
      repellendus temporibus soluta accusantium doloribus exercitationem.
      Temporibus, facilis exercitationem. Labore nihil excepturi quas ea,
      cupiditate earum nulla cumque reprehenderit aut delectus sequi, autem nemo
      nesciunt. Obcaecati quasi excepturi vel itaque sapiente quisquam neque
      fugiat facere eius dolorum dolor aspernatur saepe iste numquam deleniti
      amet quis hic, esse voluptatibus ratione et, aperiam consequatur, id
      beatae.
    </p>
    <svg class="mt-2" viewBox="0 0 100 90" width="100" height="90">
      <path
        fill="#FF0000"
        d="M99.3,25.6c-0.4-1.8-0.8-3.6-1.6-5.4c-1.4-3.6-3.8-6.8-6.6-9.6c-1.4-1.4-3-2.8-4.8-3.8
	c-1.6-1.2-3.4-2-5.4-2.6c-1.8-0.6-3.8-1.2-5.8-1.4s-4-0.2-6-0.2s-4,0.4-6,0.8c-2,0.4-4,1-5.8,1.8C55.3,6,53.5,7,51.9,8
	c-0.4,0.2-0.6,0.4-1,0.6c-3-2.8-6.2-5-10-6.4c-1.8-0.8-3.6-1.2-5.6-1.6c-2-0.4-3.8-0.6-5.8-0.6s-3.8,0.2-5.6,0.6
	c-2,0.4-3.8,0.8-5.6,1.6c-3.8,1.4-7.2,3.6-10,6.4c-1.6,1.4-2.8,3-4,4.8c-1,1.6-2,3.4-2.6,5.2c-0.6,1.8-1.2,3.6-1.4,5.6
	C0.1,26-0.1,28,0.1,30c0.2,2,0.4,4,0.6,6.4c0.6,2,1.2,3.8,2,5.6c0.8,1.8,1.8,3.6,3,5.2c1.2,1.6,2.6,3.2,4,4.6l2.6,2.4
	c0,0.2,0.2,0.4,0.4,0.4c-0.2,0.4-0.2,1-0.2,1.4c0,1,0.2,2,0.6,3c0.4,1.2,1,2,1.8,2.8c0.8,0.8,1.8,1.4,2.8,1.8s2.2,0.6,3.2,0.6
	c0.2,0,0.4,0,0.6,0c0,0,0,0.2,0,0.4c0,1,0.2,2,0.6,3c0.4,1.2,1,2,1.8,2.8c0.8,0.8,1.8,1.4,2.8,1.8c1,0.4,2.2,0.6,3.2,0.6
	c0.2,0,0.4,0,0.6,0c0,0,0,0.2,0,0.4c0,1,0.2,2,0.6,3c0.4,1.2,1,2,1.8,2.8c0.8,0.8,1.8,1.4,2.8,1.8s2.2,0.6,3.2,0.6
	c0.2,0,0.4,0,0.6,0c0,0,0,0.2,0,0.4c0,1,0.2,2,0.6,3c0.4,1.2,1,2,1.8,2.8c0.8,0.8,1.8,1.4,2.8,1.8c1,0.4,2.2,0.6,3.4,0.6
	c1.2,0,2.2-0.2,3.3-0.4c1-0.4,2-1,2.8-1.8l6.2-6c0.8-0.8,1.4-1.8,1.8-2.8c0.4-0.8,0.6-1.4,0.6-2.2C63.4,77,64.2,77,65,77
	c1.2,0,2.1-0.2,3.1-0.6c1-0.4,2-1,2.8-2c0.8-0.6,1.4-1.6,1.8-2.6s0.6-2,0.6-3c0-0.2,0-0.4,0-0.6c0.2,0,0.4,0,0.6,0
	c1.2,0,2.2-0.2,3.2-0.6c1-0.4,2-1,2.8-1.8c0.8-0.6,1.4-1.6,1.8-2.6s0.6-2,0.6-3c0-0.2,0-0.4,0-0.6c0.2,0,0.4,0,0.6,0
	c1.2,0,2.2-0.2,3.2-0.6s2-1,2.8-1.8c0.8-0.6,1.4-1.6,1.8-2.6s0.6-2,0.6-3c2.8-2.6,5-5.8,6.4-9.4c0.6-1.8,1.2-3.6,1.6-5.4
	c0.4-1.8,0.6-3.8,0.6-5.6S99.7,27.4,99.3,25.6z M21.3,59.4c-1.2,0-2.2-0.4-3-1.2c-1.6-1.4-1.6-4,0-5.6l6.2-6
	c0.8-0.8,1.8-1.2,2.8-1.2s2.2,0.4,3.2,1.2c0.8,0.8,1.2,1.8,1.2,2.8s-0.4,2-1.2,2.8l-6.2,6C23.5,59,22.5,59.4,21.3,59.4z M30.3,68
	c-1.2,0-2.2-0.4-3-1.2c-1.6-1.4-1.6-4,0-5.6l6.2-6c0.8-0.8,1.8-1.2,2.8-1.2s2.2,0.6,3.2,1.2c0.8,0.8,1.2,1.8,1.2,2.8s-0.4,2-1.2,2.8
	l-6.2,6C32.5,67.6,31.5,68,30.3,68z M39.3,76.8c-1.2,0-2.2-0.4-3-1.2s-1.2-1.8-1.2-2.8s0.4-2,1.2-2.8l6.2-6c0.8-0.8,1.8-1.2,2.8-1.2
	s2.2,0.4,3.2,1.2c1.6,1.4,1.6,4,0,5.6l-6.2,6C41.5,76.4,40.5,76.8,39.3,76.8z M57.5,78.4l-6.2,6c-0.8,0.8-1.8,1.2-3,1.2
	s-2.2-0.4-3-1.2c-1.6-1.4-1.6-4,0-5.6l6.2-6c0.8-0.8,1.8-1.2,2.8-1.2s2.2,0.4,3.2,1.2c0.8,0.8,1.2,1.8,1.2,2.8S58.3,77.6,57.5,78.4z
	 M85.8,53.8C85,54.6,84,55,82.8,55c-1.2,0-2.2-0.4-3-1.2c-0.2,0-0.2-0.2-0.4-0.2L61.2,36c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
	l18.6,18c0.8,0.8,1.2,1.8,1.2,2.8s-0.5,2-1.3,2.8s-1.8,1.2-3,1.2s-2.2-0.4-3-1.2l-18.6-18c-0.8-0.8-2.2-0.8-3,0
	c-0.8,0.8-0.8,2.2,0,3l18.5,17.8c0,0.2,0,0.2,0.2,0.4c0.8,0.8,1.2,1.8,1.2,2.8s-0.4,2-1.2,2.8c-1.6,1.6-4.2,1.6-5.8,0
	c-0.2-0.2-0.4-0.2-0.6-0.4c-0.2-0.4-0.6-0.8-1.1-1.6c-0.8-0.8-1.8-1.4-2.8-1.8c-1-0.4-2.2-0.6-3.2-0.6c-0.2,0-0.4,0-0.6,0
	c0,0,0-0.2,0-0.4c0-1-0.2-2-0.6-3c-0.4-1.2-1-2-1.8-2.8c-0.8-0.8-1.8-1.4-2.8-1.8c-1-0.4-2.2-0.6-3.2-0.6c-0.2,0-0.4,0-0.6,0
	c0,0,0-0.2,0-0.4c0-1-0.2-2-0.6-3c-0.4-1.2-1-2-1.8-2.8s-1.8-1.4-2.8-1.8s-2.2-0.6-3.2-0.6c-0.2,0-0.4,0-0.6,0c0,0,0-0.2,0-0.4
	c0-1-0.2-2-0.6-3c-0.4-1.2-1-2-1.8-2.8s-1.8-1.4-2.8-1.8c-1-0.4-2.2-0.6-3.2-0.6c-1.2,0-2.2,0.2-3.2,0.6c-1,0.4-2,1-2.8,1.8l-6.2,6
	c-0.2,0.2-0.4,0.2-0.4,0.4l-1.8-1.8c-5-4.8-8-11.6-8.4-18.6c-0.2-1.6,0-3.4,0.2-5c0.2-1.6,0.6-3.2,1.2-4.8c1.2-3.4,3-6.2,5.2-8.4
	C13.7,9,16.5,7,19.7,5.8c1.6-0.6,3.2-1,4.6-1.2c1.6-0.2,3.4-0.4,5-0.4c1.8,0,3.4,0,5,0.4C35.9,5,37.5,5.4,39.1,6
	c3.2,1.2,6,3,8.4,5.2c-0.2,0.2-0.2,0.2-0.4,0.4L27.3,30.8l1.7,1.4c1.4,1.4,3.2,2.4,5,3.2c1.8,0.6,3.6,1,5.6,1s4-0.4,5.8-1.2
	c2-0.8,3.8-2,5.2-3.4l9.2-9l26,25.4C86.6,49,87,50,87,51S86.6,53,85.8,53.8z M95.2,35.8c-0.2,1.6-0.8,3.2-1.4,4.6
	c-0.8,2.4-2.2,4.4-3.8,6.4c-0.4-0.4-0.6-0.8-1-1.2L61.4,18.8c0-0.2-0.2-0.4-0.4-0.4c-0.8-0.4-2-0.4-2.9,0.4L47.3,29.2
	c-2,2-4.8,3.2-7.8,3.2c-2.2,0-4.2-0.6-6-1.8l16.6-16c5-4.6,12.1-7.6,19.3-7.8c1.8,0,3.4,0,5.2,0.2c1.8,0.2,3.4,0.6,5,1.2
	c3.2,1,6.2,2.8,8.6,5.2c2.4,2.4,4.4,5,5.6,8.2c0.6,1.4,1,3,1.4,4.6c0.2,1.6,0.4,3.2,0.4,4.8S95.6,34.2,95.2,35.8z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about {
  color: #f7f7f7;
  background: transparent url("../assets/img-pattern.png") 0 0 repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .blue {
    color: red;
  }
  & p {
    max-width: 750px;
    & .strike {
      text-decoration: line-through;
    }
  }
}
</style>
